// Import React
import React from 'react';

// Import Spectacle Core tags
import {
  Deck,
  Heading,
  ListItem,
  List,
  Slide,
  Text,
} from 'spectacle';

// Import theme
import createTheme from 'spectacle/lib/themes/default';

import Emoji from './emoji'

import { ReactComponent as AtlassianLogo } from './atlassian.svg';
import { ReactComponent as DisneyLogo } from './disney.svg';
import { ReactComponent as DropboxLogo } from './dropbox.svg';
import { ReactComponent as RedditLogo } from './reddit.svg';
import { ReactComponent as MicrosoftLogo } from './microsoft.svg';
import { ReactComponent as PayPalLogo } from './paypal.svg';
import { ReactComponent as SentryLogo } from './sentry.svg';
import DemoImage from './demo.png';

// Require CSS
require('normalize.css');

const theme = createTheme(
  {
    primary: 'white',
    secondary: '#1F2022',
    tertiary: 'black',
    quaternary: '#CECECE',
  },
  {
    primary: 'Montserrat',
    secondary: 'Helvetica',
  }
);

export default class Presentation extends React.Component {
  render() {
    return (
      <Deck
        transition={['zoom', 'slide']}
        transitionDuration={500}
        theme={theme}
      >
        <Slide transition={['zoom']} bgColor="primary">
          <Heading size={1} fit caps lineHeight={1} textColor="secondary">
            Frontend error monitoring
          </Heading>
          <Text margin="10px 0 0" textColor="tertiary" size={1} fit bold>
            why & how
          </Text>
        </Slide>
        <Slide transition={['fade']} bgColor="tertiary">
            <div style={{ display: 'flex', color: 'white', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ width: '80%', margin: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ width: '40%', display: 'flex', textAlign: 'left', flexDirection: 'column', justifyContent: 'flexStart' }}>
                  <div>Server</div>
                  <div style={{ fontSize: '18pt', marginLeft: '10px' }}>Generates html</div>
                  <div>Browser</div>
                  <div style={{ fontSize: '18pt', marginLeft: '10px' }}>Renders (mostly) html</div>
                </div>
                <div style={{ marginTop: '30px' }}>vs</div>
                <div style={{ width: '40%', display: 'flex', textAlign: 'left', flexDirection: 'column' }}>
                  <div style={{ marginLeft: '50px' }}>Server</div>
                  <div style={{ fontSize: '18pt', marginLeft: '60px' }}>Delivers source code</div>
                  <div style={{ marginLeft: '50px' }} >Browser</div>
                  <div style={{ fontSize: '18pt', marginLeft: '60px'  }}>Interprets</div>
                  <div style={{ fontSize: '18pt', marginLeft: '60px'  }}>Executes</div>
                  <div style={{ fontSize: '18pt', marginLeft: '60px'  }}>Renders</div>
                </div>
              </div>
            </div>
            <div style={{ color: 'white', margin: '10px 0px 50px 0px' }}>
              Different browsers, versions, resolutions, device capabilities ...
            </div>
            <div style={{ color: 'white', margin: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                <div>C/Java/Pascal/Rust</div>
                <div>Statically typed</div>
                <div>Compile time errors</div>
              </div>
              <div>vs</div>
              <div style={{ display: 'flex', width: '40%', flexDirection: 'column' }}>
              <div>JavaScript/Python</div>
              <div>Dynamically typed</div>
              <div>Runtime errors</div>
            </div>
          </div>
        </Slide>
        <Slide transition={['fade']} bgColor="primary" textColor="tertiary">
          <span style={{ color: '#E03E2F' }}><SentryLogo t/></span>
          <List style={{ marginTop: '50px' }}>
            <ListItem>Open Source 23k github <span style={{ color: '#fcc603' }}><Emoji label="star" symbol="★"/></span></ListItem>
            <ListItem>On-premise or as a service on sentry.io</ListItem>
            <ListItem>IKEA has business account</ListItem>
          </List>
          <div style={{ marginTop: '50px', width: '100%', height: '60px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <AtlassianLogo style={{ width: '200px' }} />
            <DisneyLogo style={{ width: '200px' }} />
            <DropboxLogo style={{ width: '200px' }} />
          </div>
          <div style={{ marginTop: '50px', width: '100%', height: '60px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MicrosoftLogo style={{ width: '200px' }} />
            <PayPalLogo style={{ width: '200px' }} />
            <RedditLogo style={{ width: '200px' }} />
          </div>
        </Slide>
        <Slide transition={['fade']} bgColor="tertiary" textColor="primary">
          <Heading style={{ marginTop: '50px' }} size={5} textColor="primary">
            Demo!
          </Heading>
          <img style={{ width: '80%', marginBottom: '50px', marginTop: '20px' }} src={DemoImage} alt="Demo" />
        </Slide>
      </Deck>
    );
  }
}
